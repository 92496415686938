window.showMore = function showMore(element, showElementId) {
    let showElement = document.getElementById(showElementId);

    if (showElement) {
        showElement.classList.remove('u-d--none');
        element.classList.add('u-d--none');
    }
};


window.showLess = function showLess(showBtnId, showElementId) {
    let showBtnElement = document.getElementById(showBtnId);
    let showElement = document.getElementById(showElementId);

    if (showElement) {
        showElement.classList.add('u-d--none');
    }
    if (showBtnElement) {
        showBtnElement.classList.remove('u-d--none');
    }
};
