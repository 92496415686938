import "./toastify";

document.querySelectorAll(".js-contact-form").forEach((element) => {
    element.addEventListener("submit", function (e) {
        e.preventDefault();
        submitContactForm(e);
    });
});

// Contact form send
function submitContactForm(element) {
    let url = element.target.action;
    let btn = element.target.getElementsByTagName("button")[0];
    btn.disabled = true;
    btn.classList.add("loading");

    let formData = {};
    let form = element.target;
    let data = new FormData(form);
    for (let [key, value] of data.entries()) {
        formData[key.toString()] = value;
    }
    let elementWithData = document.getElementById("contact-form-reservation");
    fetch(url, {
        method: "POST",
        credentials: "same-origin",
        mode: "same-origin",
        headers: {
            "Cache-Control": "no-cache",
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": document
                .getElementsByName("csrf-token")[0]
                .getAttribute("content"),
        },
        body: JSON.stringify(formData),
    })
        .then((response) => response.json())
        .then((data) => {
            if (data.status === 1) {
                // dataLayer.push({ event: "email_sent" });
                showToast(data.msg, "success");
                form.reset();
            } else {
                let obj = data.msg;
                let message = "";
                if (typeof obj === "object") {
                    Object.keys(obj).map(function (key) {
                        message += obj[key] + "\n";
                    });
                } else {
                    message = obj;
                }
                showToast(message, "error");
            }

            btn.disabled = false;
            btn.classList.remove("loading");

            if (
                elementWithData &&
                !elementWithData.classList.contains("u-d--none")
            ) {
                elementWithData.classList.add("u-d--none");
            }
        });
}

window.clickOnFormLinkButton = function clickOnFormLinkButton(
    contactFormId,
    elementId,
    itemTitle = "",
    inputName = "reservation"
) {
    let contactForm = document.getElementById(contactFormId);
    let element = document.getElementById(elementId);
    if (contactForm && element) {
        let elementForReservationItem = element.querySelector(
            'input[name="' + inputName + '"]'
        );
        elementForReservationItem.value = itemTitle;
        element.classList.remove("u-d--none");
    }

    showContactForm();
};

/**
 *
 * Toggle contact form with placehodler section
 *
 */

const formPlaceholder = document.querySelector(".form-placehoder");
if (formPlaceholder) {
    const formPlaceholderButton = formPlaceholder.querySelector("button");
    const formPlaceholderBox = document.querySelector(".form-box");

    formPlaceholderButton.addEventListener("click", (e) => {
        e.preventDefault();

        showContactForm();
    });

    function showContactForm() {
        formPlaceholder.classList.add("hide");
        setTimeout(() => {
            formPlaceholder.classList.add("hidden");
            formPlaceholder.tabIndex = -1;

            formPlaceholderBox.classList.remove("hidden");
            setTimeout(() => {
                formPlaceholderBox.classList.remove("hide");
                formPlaceholderBox.tabIndex = 1;
            }, 500);
        }, 500);
    }
}
