const slideDown = (element) =>
    (element.style.height = `${element.scrollHeight}px`);
const slideUp = (element) => (element.style.height = "0px");

const slideDownItems = document.querySelectorAll(".js-slideDown");

slideDownItems.forEach((item) => {
    item.addEventListener("click", function () {
        let element = document.querySelector(
            this.getAttribute("href")
                ? this.getAttribute("href")
                : this.getAttribute("data-href")
        );

        if (element.style.height === "0px" || element.style.height === "") {
            slideDown(element);
        } else {
            slideUp(element);
        }
    });
});
