import AOS from "aos";

document.addEventListener("DOMContentLoaded", () => {
    AOS.init({
        duration: 1000,
        // once: true,
        once: false,
        mirror: false,
    });
});
